#login-screen {
    background-color: #382f2f;

    .ref-button {
        background-color: #1f1b1b;
        border-color: #1f1b1b;
    }
    
    .main-container {
        background-color: #FFF;
        height: 650px;
        box-shadow: rgb(0 0 0 / 47%) 6px 6px 8px;
        overflow: hidden;
        
        .login-title-1 {
            font-size: 60px;
            font-weight: 400;
            letter-spacing: -3px;
            color: #211f1f;
        }

        .login-title-2 {
            font-size: 65px;
            font-weight: 900;
            letter-spacing: -2px;
            margin-top: -8px;
            line-height: 64px;
            color: #211f1f;
        }

        .login-entrar-button {
            padding: 13px 16px;
            width: 100%;
            background-color: #4b3e3e;
            border-color: #251f1f;
            font-weight: 400;
            letter-spacing: 1px;
        }

        .login-entrar-button:hover {
            background: #2c2525;
            border-color: #251f1f;
        }

        input:enabled:hover {
            border-color: #4b3e3e;
        }

        input:enabled:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #ffcdb9;
            border-color: #4b3e3e;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #faf4ef inset !important;
        }

        .cicle-buttons-container {
            margin-top: 10px;

            .user-register-button {
                background-color: #88605d;
                border-color: #88605d;
            }
            
            .forgot-password-button {
                margin-left: 10px;
                background-color: #e39467;
                border-color: #e39467;
            }
        }

    }
    
    .login-password-input{
        .p-icon-field {
            width: 100%;
        }
    }

    @media only screen and (min-width: 1400px) and (max-width: 1600px) {
        .main-container {
            width: 60% !important;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .main-container {
            width: 70% !important;
        }
    }
    
    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
        .main-container {
            width: 90% !important;
        }
    }

    @media only screen and (min-width: 700px) and (max-width: 1000px) {
        .main-container {
            width: 90% !important;
            .top-coffe-image {
                top: -20px !important;
            }
            .bottom-coffe-image {
                bottom: -20px !important;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .main-container {
            height: 700px;
            min-height: 700px;
            width: 90% !important;
            flex-direction: column-reverse;
            .login-content {
                height: 70% !important;
                width: 100% !important;
                padding: 10px;
            }
            .image-container {
                height: 30% !important;
            }

            .top-coffe-image {
                display: none;
            }
            .bottom-coffe-image {
                display: none;
            }

            .login-title-1 {
                font-size: 50px;
            }
            .login-title-2 {
                font-size: 55px;
                line-height: 50px;
            }
        }
    }
    
}
.forgot-password-tooltip {
    .p-tooltip-text {
        background-color: #e39467 !important; /* Escolha sua cor de fundo desejada */
        color: white !important; /* Cor do texto do tooltip */
    }
    /* Arrow color */
    .p-tooltip-arrow {
        border-bottom-color: #e39467 !important; /* Ajuste para corresponder à cor de fundo */
    }
}
.user-register-tooltip {
    .p-tooltip-text {
        background-color: #88605d !important; /* Escolha sua cor de fundo desejada */
        color: white !important; /* Cor do texto do tooltip */
    }
    /* Arrow color */
    .p-tooltip-arrow {
        border-bottom-color: #88605d !important; /* Ajuste para corresponder à cor de fundo */
    }
}
.ref-tooltip {
    .p-tooltip-text {
        background-color: #1f1b1b !important; /* Escolha sua cor de fundo desejada */
        color: white !important; /* Cor do texto do tooltip */
    }
    /* Arrow color */
    .p-tooltip-arrow {
        border-top-color: #1f1b1b !important; /* Ajuste para corresponder à cor de fundo */
    }
}